.ForecastTable {
    margin: auto;
    background-color: rgba(255, 255, 255, 0.61);
    border-radius: 10px;
    padding: 15px;
    width: 75%;
    border-style: dotted;
}

.ForecastTable--header {
    text-align: center;
    font-size: 30px;

}

.ForecastTable--row {
    display: flex;
    justify-content: space-between;
}
