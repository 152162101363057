.Weather {
    padding: 5px;
}

.Weather--temp {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
}

.Weather--time {
    text-align: center;
}