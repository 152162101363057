
.Weather {
    padding: 5px;
}

.Weather--temp {
    text-align: center;
    font-weight: bold;
    font-size: 1.2em;
}

.Weather--time {
    text-align: center;
}
.ForecastTable {
    margin: auto;
    background-color: rgba(255, 255, 255, 0.61);
    border-radius: 10px;
    padding: 15px;
    width: 75%;
    border-style: dotted;
}

.ForecastTable--header {
    text-align: center;
    font-size: 30px;

}

.ForecastTable--row {
    display: flex;
    justify-content: space-between;
}

.Headline{

    font-size: 40px;
    text-decoration: underline ;
    padding: 10px;

}
